"use client";

import React, { CSSProperties } from "react";
import { useRouter } from "next/navigation";
import nProgress from "nprogress";

interface Props {
  href: any;
  children: any;
  style?: CSSProperties;
  title?: string;
  scroll?: boolean;
  className?: string;
}

export default function FakeLink(props: Props) {
  const { href, children, style, title, scroll = true, className } = props;
  const router = useRouter();

  const followLink = () => {
    nProgress.start();
    router.push(href, { scroll: scroll ?? true });
  };

  return (
    <span
      role={href ? "link" : undefined}
      title={title}
      aria-disabled={!href}
      tabIndex={0}
      onKeyPress={(evt) => {
        evt.preventDefault();
        if (evt.which === 13) {
          if (href) {
            followLink();
          }
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        if (href) {
          followLink();
        }
      }}
      style={style}
      className={className}
    >
      {children}
    </span>
  );
}

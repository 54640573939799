import React from "react";
import { AffiliatePromotion } from "~/typings/types";
import AppAlertWrapper from "./AppAlertWrapper";
import AppPromotionCode from "~/c/AppCommon/AppPromotionCode";

type AppPromotionProps = {
  promotion: AffiliatePromotion;
  urlName: string;
};

const AppPromotion = (props: AppPromotionProps) => {
  const { promotion, urlName } = props;

  if (promotion.badgeText && urlName && urlName !== "") {
    return (
      <AppAlertWrapper desc="promo" type={"positive"}>
        <a
          data-testid={`promo-${urlName}`}
          href={`/outgoing/software/${urlName}`}
          rel="nofollow"
        >
          <div className="promoBadge">
            <span>Promotion 😀</span>
            <p>
              {promotion.badgeText}

              <AppPromotionCode code={promotion.promoCode} />
            </p>
          </div>
        </a>
      </AppAlertWrapper>
    );
  } else {
    return <></>;
  }
};
export default AppPromotion;

import React, { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import { Captions, Counter } from "yet-another-react-lightbox/plugins";
import { ItemImage } from "~/typings/types";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import useSWR from "swr";
import { callAPIClientSide } from "~/helpers/api";

type LightboxModalProps = {
  mediaItems: ItemImage[];
  isOpen: boolean;
  onClose(): any;
  startFileName: string;
  itemId?: string;
};

const LightboxModal = (props: LightboxModalProps) => {
  const { mediaItems, isOpen, onClose, startFileName, itemId } = props;

  const fetcher = (url) => callAPIClientSide(url);
  const { data } = useSWR(
    itemId && mediaItems.length > 2 ? `/items/${itemId}/screenshots/` : null,
    fetcher,
  );

  const [screenshots, setScreenshots] = useState(mediaItems);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const startIndex: number = screenshots.findIndex(
      (x) => x.fileName == startFileName,
    );

    setCurrentImageIndex(startIndex);

    setSlides(
      screenshots.map((screenshot) => {
        const signedImage = screenshot.signedImages.find(
          (x) => x.size === "1200x1200" || x.size === "2400x2400",
        );
        return {
          src: signedImage.signedURL,
          alt: screenshot.description,
          description: screenshot.description,
        };
      }),
    );
  }, [startFileName, screenshots]);

  useEffect(() => {
    if (data) {
      setScreenshots(data);
    }
  }, [data]);

  return (
    <>
      <Lightbox
        index={currentImageIndex}
        plugins={[Captions, Counter]}
        captions={{ descriptionTextAlign: "center" }}
        open={isOpen}
        close={() => onClose()}
        slides={slides}
      />
    </>
  );
};
export default LightboxModal;

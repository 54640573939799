import React from "react";
import AppAlert from "./AppAlert";
import Spacer from "../Spacer";
import { ItemAlert } from "~/typings/types"; // Adjust the import path as needed

type AppAlertListProps = {
  itemAlerts: ItemAlert[];
  hideItemAlerts?: string[];
};

const AppAlertList = ({ itemAlerts, hideItemAlerts }: AppAlertListProps) => {
  const visibleAlerts = itemAlerts.filter(
    (alert) => !(hideItemAlerts && hideItemAlerts.includes(alert.type)),
  );

  if (!visibleAlerts.length) return <Spacer space={4}></Spacer>;

  return (
    <div className="col-span-2 text-sm">
      <Spacer space={2} />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {visibleAlerts.map((alert, index) => (
          <AppAlert key={index} alert={alert} />
        ))}
      </div>
      <Spacer space={2} />
    </div>
  );
};

export default AppAlertList;

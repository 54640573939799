"use client";

import React, { useContext, useEffect, useState } from "react";
import { FaHeart, FaRegHeart, FaTimes } from "react-icons/fa";
import useSWR from "swr";
import { ListOfUsers } from "~/c/ListOfUsers";
import { callAPIClientSide, callAPIForSWR } from "~/helpers/api";
import { CommonUser, RootContextValues } from "~/typings/types";

import styles from "./ModernLikeButton.module.scss";
import { RootContext } from "~/c/Contexts/RootContext";
import { clearCacheClientSide } from "~/helpers/api-cache-helper";
import { WidgetTypes } from "~/c/DataCollector/types";

import IconWithBorder from "~/c/Common/IconWithBorder";
import { plural } from "~/helpers/plurals";
import classNames from "classnames";
import { useDataCollector } from "~/components/AppRouter/hooks/useDataCollector";
import { DataCollectorWrapper } from "~/components/AppRouter/DataCollectorWrapper";

interface ModernLikeButtonProps {
  itemId: string;
  urlName: string;
  name: string;
  onCustomDCEvent?(): any;
  serverLikes: number;
}

export interface LikeButtonData {
  totalLikes: number;
  likedBy: CommonUser[];
  isLiked: boolean;
}

export const ModernLikeButton: React.FC<ModernLikeButtonProps> = (
  props: ModernLikeButtonProps,
) => {
  const { itemId, urlName, name, onCustomDCEvent, serverLikes = 0 } = props;

  const rootContext = useContext<RootContextValues>(RootContext);
  const isLoggedIn = !!rootContext.user;

  const [isIconHovered, setIsIconHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { renderDCWidget, showDataCollector, closeDataCollector } =
    useDataCollector(itemId);

  const shouldFetch = isLoggedIn || serverLikes > 0;

  const fetcher = (url) =>
    callAPIForSWR(url, isLoggedIn ? rootContext.userId : null, true, 360);
  const { data, mutate } = useSWR<LikeButtonData>(
    shouldFetch ? `/items/${itemId}/likes/` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    },
  );

  const toggleLike = async (likeState) => {
    if (isLoggedIn && rootContext.isInRole("freezer")) {
      setIsLoading(true);

      await callAPIClientSide(
        `/items/like/`,
        null,
        "POST",
        rootContext.userId,
        {
          itemId: itemId,
        },
      );

      await clearCacheClientSide(
        {
          urlName: urlName,
          itemId: itemId,
        },
        rootContext.isCrewAdmin,
      );

      setIsLoading(false);

      mutate();
    }

    const widgets: WidgetTypes[] = ["Comment", "Features"];

    // if (silentAction) {
    //   widgets = ["SilentAction", ...widgets];
    // }

    if (likeState && !onCustomDCEvent) {
      showDataCollector({ widgets });
    }

    if (onCustomDCEvent) {
      onCustomDCEvent();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      mutate();
    }, 400);
    return () => clearTimeout(timer);
  }, [mutate]);

  let showIcon = <FaRegHeart />;

  if (!isIconHovered && data && data.isLiked) {
    showIcon = <FaHeart />;
  }

  if (isIconHovered && data && data.isLiked) {
    showIcon = <FaTimes />;
  }

  if (isIconHovered && data && !data.isLiked) {
    showIcon = <FaHeart />;
  }

  if (isLoading) {
    showIcon = (
      <div className={classNames("spinner-icon", "spinner-icon-small")}>
        &nbsp;
      </div>
    );
  }

  return (
    <>
      <div className={styles.likeWrapper}>
        <span
          title={data && data.isLiked ? "Remove your like" : "Like " + name}
          className={`${styles.heart} ${
            data && data.isLiked ? styles.liked : ""
          }`}
          onMouseEnter={() => setIsIconHovered(true)}
          onMouseLeave={() => setIsIconHovered(false)}
          onClick={(e) => {
            toggleLike(data && !data.isLiked);

            e.preventDefault();
          }}
        >
          <IconWithBorder
            text={
              data
                ? data.totalLikes > 0
                  ? data.totalLikes.toString() +
                    plural(" like", data.totalLikes)
                  : "Like"
                : serverLikes > 0
                  ? serverLikes.toString() + plural(" like", serverLikes)
                  : "Like"
            }
          >
            {showIcon}
          </IconWithBorder>
        </span>
        {data && (
          <div className={styles.userList}>
            <ListOfUsers
              totalOpinions={data.totalLikes}
              users={data.likedBy}
              titleText=""
              size="large"
              userIncluded={data.isLiked}
              type="app-no-meta"
            ></ListOfUsers>
          </div>
        )}
      </div>
      <DataCollectorWrapper
        renderDCWidget={renderDCWidget}
        mainItemName={name}
        onClose={closeDataCollector}
      />
    </>
  );
};

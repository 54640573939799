"use client";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { cn } from "@/lib/utils";
import IconWithBorder from "~/components/Common/IconWithBorder";

interface AppItemExpanderProps {
  isExpanded: boolean;
  onToggle: () => void;
  className?: string;
  itemId: string | number;
  hideWhenExpanded?: boolean;
  showTextOnMobile?: boolean;
}

export function AppItemExpander({
  isExpanded,
  onToggle,
  className,
  itemId,
  hideWhenExpanded = false,
  showTextOnMobile = false,
}: AppItemExpanderProps) {
  // If hideWhenExpanded is true and the component is expanded, don't render it
  if (hideWhenExpanded && isExpanded) {
    return null;
  }

  const handleToggle = () => {
    onToggle();

    // If we're collapsing (currently expanded), scroll to the item
    //if (isExpanded) {
    // Use setTimeout to ensure the toggle state has been processed
    setTimeout(() => {
      const itemElement = document.getElementById(`app-item-${itemId}`);
      if (itemElement) {
        // Get the element's position
        const elementRect = itemElement.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;

        // Scroll to element with an offset of 100px (or adjust as needed)
        const scrollPosition = absoluteElementTop - 170;

        // Scroll with smooth behavior
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }, 50);
    //}
  };

  return (
    <button
      data-testid={`app-item-expander`}
      onClick={handleToggle}
      className={cn(
        "flex items-center gap-1 hover:text-meta transition-colors",
        className,
      )}
      aria-expanded={isExpanded}
      data-expanded={isExpanded}
      data-item-id={itemId}
    >
      <span
        className={cn("text-sm hidden md:block", showTextOnMobile && "block")}
      >
        {isExpanded ? "Collapse app info" : "Expand app info"}
      </span>
      <IconWithBorder customStyle={["smaller", "round"]}>
        {isExpanded ? (
          <FaChevronUp size={10} className="text-blue-700" />
        ) : (
          <FaChevronDown size={10} className="text-blue-700" />
        )}
      </IconWithBorder>
    </button>
  );
}

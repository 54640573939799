export const findMatchingObjectsByProperty = (
  arrOne: any[],
  arrTwo: any[],
  property: string,
): any[] => {
  const matching = arrOne.filter((r) => {
    return arrTwo.find((x) => x[property] == r[property]);
  });

  return matching;
};

export const arrayToCommaSeparatedWithSuffix = (
  arr: string[],
  suffix: string,
) => {
  return arr.join(", ").replace(/,(?!.*,)/gim, suffix);
};

export const getRandomItems = (array, numItems) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .slice(0, numItems)
    .map(({ item }) => item);
};

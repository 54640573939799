import React from "react";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AppAlertWrapper.module.scss";

interface AppAlertWrapperProps {
  children: any;
  desc: string;
  type: string;
}

const AppAlertWrapper = (props: AppAlertWrapperProps) => {
  const { desc, type, children } = props;

  return (
    <div key={desc} className={`${styles.appNotice} ${styles[type]}`}>
      {children}
    </div>
  );
};
export default AppAlertWrapper;

//import Head from "next/head";
import React from "react";
import { getScreenshotUrl } from "~/helpers/urls";
import { ItemImage, sizeType } from "~/typings/types";
import LazyImg from "~/components/LazyImg";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ImageCollectionImage.module.scss";
import classNames from "classnames";

type ImageCollectionImageProps = {
  isHero: boolean;
  screenshot: ItemImage;
  screenshotsCount: number;
  aspectType: string;
  description: string;
  landscapeWidth?: string;
  portraitWidth?: string;
  fill?: boolean;
  imageSize?: sizeType;
  showOverlay?: boolean;
};

const ImageCollectionImage = (props: ImageCollectionImageProps) => {
  const {
    screenshot,
    isHero,
    screenshotsCount,
    aspectType,
    description,
    landscapeWidth,
    portraitWidth,
    fill,
    imageSize,
    showOverlay,
  } = props;
  const { type, fileName, signedImages } = screenshot;

  const currentUrl = getScreenshotUrl(fileName, signedImages, aspectType);

  const currentUrlHiRes = getScreenshotUrl(
    fileName,
    signedImages,
    aspectType,
    "hi-res-",
  );

  const altText = description ? description : `${type}`;

  let customStyle = {};
  let customClass;
  let portraitOverlay;

  if (aspectType == "landscape") {
    customStyle = {
      width: landscapeWidth ? landscapeWidth : "280px",
    };
  } else if (aspectType == "portrait") {
    customStyle = {
      width: portraitWidth ? portraitWidth : "133px",
    };
    portraitOverlay = styles.portrait;
  }

  if (aspectType == "portrait-new") {
    customClass = styles.portraitNew;
    portraitOverlay = styles.portrait;
  }

  if (fill && aspectType !== "portrait-new") {
    customClass = styles.fill;
  }

  if (imageSize == "smaller") {
    customStyle = {
      ...customStyle,
      width: "95px",
      height: "64px",
    };
  }

  return (
    <>
      {/* {isHero && (
        <Head>
          <link rel="preload" as="image" href={currentUrl}></link>
        </Head>
      )} */}
      {showOverlay && (
        <div className={classNames(styles.imageOverlay, portraitOverlay)}>
          +{screenshotsCount - 3}
        </div>
      )}
      <LazyImg
        customClass={customClass}
        style={customStyle}
        srcset={`${currentUrlHiRes} 1.5x,${currentUrlHiRes} 2x`}
        notLazy={isHero}
        alt={altText}
        src={currentUrl}
      />
    </>
  );
};
export default ImageCollectionImage;

import React from "react";
import { ItemAlert } from "~/typings/types";
import RenderMarkdownWithLinks from "~/components/Common/RenderMarkdownWithLinks";
import AppAlertWrapper from "./AppAlertWrapper";

type AppAlertProps = { alert: ItemAlert };

const AppAlert = (props: AppAlertProps) => {
  const { alert } = props;

  let desc = "&nbsp;";
  let header = alert.type;
  if (alert.type === "Legal Warning") {
    header = "Use with caution";
  }

  if (alert.description) {
    desc = alert.description;
  } else {
    if (alert.type === "Legal Warning") {
      desc = "Flagged as potentially breaking copyright or other laws";
    } else if (alert.type === "NSFW Warning") {
      desc =
        "Flagged as NSFW material, usually adult / nudity. Please proceed only if you're comfortable with that.";
    } else {
      desc = alert.description ? alert.description : "&nbsp;";
    }
  }

  return (
    <AppAlertWrapper desc={alert.description} type={alert.severity}>
      <span
        data-testid={`app-notice-${alert.type.replace(" ", "-").toLowerCase()}`}
      >
        {header}
      </span>{" "}
      {alert.description !== "" && <RenderMarkdownWithLinks md={desc} />}
    </AppAlertWrapper>
  );
};
export default AppAlert;

"use client";
import React, { useContext, useEffect, useRef } from "react";
import { RootContext } from "~/c/Contexts/RootContext";
import { RootContextValues } from "~/typings/types";

interface AppPromotionCodeProps {
  code: string;
}

const AppPromotionCode: React.FC<AppPromotionCodeProps> = ({ code }) => {
  const promoCode = useRef<HTMLSpanElement>();

  const rootContext = useContext<RootContextValues>(RootContext);

  useEffect(() => {
    const currentElement = promoCode.current;

    const handleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const textToCopy = currentElement.textContent;
      navigator.clipboard.writeText(textToCopy).catch((err) => {
        console.error("Failed to copy text: ", err);
      });

      rootContext.setInfo({
        autoClose: 3000,
        position: "bottom-center",
        body: "Promo code copied to clipboard",
      });
    };

    if (currentElement) {
      currentElement.addEventListener("click", handleClick);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener("click", handleClick);
      }
    };
  }, [rootContext]);

  if (!code) {
    return null;
  }

  return (
    <>
      , use code
      <span
        ref={promoCode}
        style={{
          border: "1px dashed var(--positiveGreener)",
          padding: "0.1rem 0.3rem",
          marginLeft: "0.5rem",
          backgroundColor: "var(--positiveGreenerLight)",
          color: "var(--positiveGreenerDark)",
          cursor: "copy",
          display: "inline-block",
        }}
      >
        {code}
      </span>
    </>
  );
};

export default AppPromotionCode;

"use client";

import { useEffect, useState } from "react";
import React from "react";

interface AppItemExpandableContentProps {
  children: React.ReactNode;
  itemId: string | number;
  isDefaultExpanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
}

export function AppItemExpandableContent({
  children,
  itemId,
  isDefaultExpanded = false,
  onExpandedChange,
}: AppItemExpandableContentProps) {
  // Initialize with the default expanded state
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  // Notify parent of state changes
  useEffect(() => {
    onExpandedChange?.(isExpanded);
  }, [isExpanded, onExpandedChange]);

  // Update expanded state when prop changes
  useEffect(() => {
    setIsExpanded(isDefaultExpanded);
  }, [isDefaultExpanded]);

  useEffect(() => {
    // Listen for the custom event, but only respond if it's for this item
    const handleExpand = (event: CustomEvent) => {
      if (event.detail.itemId === itemId) {
        if (event.detail.type === "toggle") {
          setIsExpanded((current) => !current);
        } else if (event.detail.isExpanded !== undefined) {
          setIsExpanded(event.detail.isExpanded);
        }
      }
    };

    // Add event listener
    document.addEventListener(
      "app-item-expanded",
      handleExpand as EventListener,
    );

    // Clean up
    return () => {
      document.removeEventListener(
        "app-item-expanded",
        handleExpand as EventListener,
      );
    };
  }, [itemId]);

  // Apply expanded state to all elements that need it within this item
  useEffect(() => {
    // Get the container for this specific item
    const container = document.getElementById(`app-item-${itemId}`);
    if (!container) return;

    // Find all elements that need to respond to expansion state
    const collapsedBadgeRow = container.querySelector("#collapsed-badge-row");
    const expandedBadgeRow = container.querySelector("#expanded-badge-row");
    const expandedThumbnailsRow = container.querySelector(
      "#expanded-thumbnails-row",
    );
    const expandableContent = container.querySelector(
      "#app-expandable-content",
    );
    const expandableBelowContent = container.querySelector(
      "#app-expandable-content-below",
    );
    const description = container.querySelector("#app-description");

    // Apply classes based on expanded state
    if (collapsedBadgeRow) {
      collapsedBadgeRow.classList.toggle("hidden", isExpanded);
    }

    if (expandedBadgeRow) {
      expandedBadgeRow.classList.toggle("hidden", !isExpanded);
    }

    if (expandedThumbnailsRow) {
      expandedThumbnailsRow.classList.toggle("hidden", !isExpanded);
    }

    if (expandableContent) {
      expandableContent.classList.toggle("hidden", !isExpanded);
    }

    if (expandableBelowContent) {
      expandableBelowContent.classList.toggle("hidden", !isExpanded);
    }

    if (description) {
      if (isExpanded) {
        description.classList.add("line-clamp-4");
        description.classList.remove("line-clamp-2");
      } else {
        description.classList.add("line-clamp-2");
        description.classList.remove("line-clamp-4");
      }
    }

    // Set a data attribute on the container itself for potential global styling
    container.setAttribute("data-expanded", String(isExpanded));
  }, [isExpanded, itemId]);

  return <div className="app-item-container">{children}</div>;
}

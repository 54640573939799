import Link from "next/link";
import ExternalLink from "~/c/Common/ExternalLink";
import { UrlEntity, getAffiliateUrl, getUrl } from "~/helpers/urls";

interface AppLinkProps {
  linkTo: UrlEntity;
  app: any; // replace with your actual App type
  children: React.ReactNode;
}

export const AppLink: React.FC<AppLinkProps> = ({ linkTo, app, children }) => {
  if (linkTo === "external") {
    return (
      <ExternalLink
        rel="sponsored noopener"
        href={getAffiliateUrl(app.urlName)}
        className="no-link-color"
        urlName={app.urlName}
      >
        {children}
      </ExternalLink>
    );
  } else {
    return (
      <Link
        title={
          linkTo === "app-alternatives"
            ? `${app.alternatives} ${app.name} Alternatives`
            : `Learn more about ${app.name}`
        }
        href={getUrl(linkTo, [app.urlName])}
        translate="no"
        className="no-link-color"
      >
        {children}
      </Link>
    );
  }
};

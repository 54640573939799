"use client";
import Link from "next/link";
import React, { useContext, useState, useEffect, useRef } from "react";
import useSWR from "swr";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./HighlightFeatureListItem.module.scss";
import { Popover } from "react-tiny-popover";
import {
  FaChevronDown,
  FaGlobe,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import { HighlightFeaturesSize } from "~/c/AppCommon/HighlightFeatures";
import {
  FeatureWithUserInfo,
  RootContextValues,
  VoteType,
} from "~/typings/types";
import { plural } from "~/helpers/plurals";
import classNames from "classnames";
import { IoGameController } from "react-icons/io5";
import AppIcon from "~/c/AppCommon/AppIcon";
import { getUrl } from "~/helpers/urls";
import UpDownVoter from "~/c/Common/UpDownVoter";
import IconWithBorder from "~/c/Common/IconWithBorder";
import Spacer from "~/c/Spacer";
import { callAPIForSWR } from "~/helpers/api";
import { RootContext } from "~/c/Contexts/RootContext";

type FeatureNameTransform = "remove-integration";

interface HighlightFeatureListItemProps {
  itemName?: string;
  size?: HighlightFeaturesSize;
  feature: FeatureWithUserInfo;
  currentItemId: string;
  onVote?: (voteState: VoteType) => void;
  featureNameTransform?: FeatureNameTransform;
}

const HighlightFeatureListItem = (props: HighlightFeatureListItemProps) => {
  const {
    size,
    itemName,
    feature,
    currentItemId,
    onVote,
    featureNameTransform,
  } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const closeTimeoutRef = useRef<NodeJS.Timeout>();
  const wasClickedRef = useRef(false);

  const rootContext = useContext<RootContextValues>(RootContext);
  const isLoggedIn = !!rootContext.user;

  const fetcher = (url) =>
    callAPIForSWR(url, isLoggedIn ? rootContext.userId : null, true, 360);

  const { data: hasVoted } = useSWR(
    popoverOpen
      ? `/items/${currentItemId}/features/has-voted/${feature.urlName}/`
      : null,
    fetcher,
    { revalidateOnFocus: false },
  );

  const toggleVote = async (voteState: VoteType) => {
    onVote(voteState);
  };

  let popularityAmongAlternatives = "";

  if (feature.itemAlternativesPopularity > 50) {
    popularityAmongAlternatives = "very common";
  } else if (feature.itemAlternativesPopularity > 20) {
    popularityAmongAlternatives = "common";
  } else {
    popularityAmongAlternatives = "rare";
  }

  let featureIcon = (
    <span
      className={classNames(
        styles.featurePoint,
        {
          [styles.gray]: !feature.hot,
        },
        {
          [styles.property]: feature.type === "Property",
        },
      )}
    ></span>
  );

  if (!feature.urlName && feature.name != "Game Series") {
    featureIcon = (
      <>
        <FaGlobe size={11} />
        &nbsp;
      </>
    );
  }

  if (!feature.urlName && feature.name == "Game Series") {
    featureIcon = (
      <>
        <IoGameController size={16} />
        &nbsp;
      </>
    );
  }

  if (feature.tagItem != null) {
    const icon = feature.tagItem.images.find((image) => image.type === "Icon");
    featureIcon = (
      <>
        <AppIcon
          appName={feature.tagItem.name}
          imgObject={icon}
          dimension={20}
          className="w-[16px] h-[16px] rounded-full"
        />
        &nbsp;
      </>
    );
  }

  const isIntegration = feature.urlName?.toLowerCase().endsWith("-integration");

  const handleMouseEnter = () => {
    if (wasClickedRef.current) return;

    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setPopoverOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (wasClickedRef.current) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    closeTimeoutRef.current = setTimeout(() => {
      setPopoverOpen(false);
    }, 300);
  };

  const handleClick = () => {
    // Clear any pending timeouts
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (closeTimeoutRef.current) clearTimeout(closeTimeoutRef.current);

    wasClickedRef.current = true;
    setPopoverOpen(!popoverOpen);

    // Reset after a short delay to allow hover behavior again
    setTimeout(() => {
      wasClickedRef.current = false;
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <li
        className={classNames(styles.listItem, styles[size])}
        key={feature.urlName}
      >
        <Popover
          isOpen={popoverOpen}
          padding={4}
          positions={["bottom"]}
          containerStyle={{
            zIndex: "9999",
          }}
          reposition={true}
          transformMode="relative"
          onClickOutside={() => {
            wasClickedRef.current = false;
            setPopoverOpen(false);
          }}
          content={() => {
            return (
              <div
                className={`${styles.popoverContent} transitionDefault`}
                onMouseEnter={() => {
                  if (wasClickedRef.current) return;
                  if (closeTimeoutRef.current) {
                    clearTimeout(closeTimeoutRef.current);
                  }
                }}
                onMouseLeave={handleMouseLeave}
              >
                <p>
                  {feature.shortDescription &&
                    feature.shortDescription.replace("{app}", itemName)}
                </p>
                {feature.tagItem && (
                  <p>
                    {isIntegration ? (
                      <>
                        {itemName} has an integration with&nbsp;
                        <Link
                          href={getUrl("app-about", [feature.tagItem.urlName])}
                        >
                          {feature.tagItem.name}
                        </Link>
                      </>
                    ) : (
                      <>
                        {itemName} is related to&nbsp;
                        <Link
                          href={getUrl("app-about", [feature.tagItem.urlName])}
                        ></Link>
                      </>
                    )}
                  </p>
                )}
                {feature.urlName && (
                  <>
                    <p>
                      The &apos;{feature.name}&apos;-
                      {feature.type.toLowerCase()} has been upvoted by{" "}
                      <strong>{feature.likes}</strong>{" "}
                      {plural("user", feature.likes)} and is{" "}
                      <strong>{popularityAmongAlternatives}</strong> among{" "}
                      {itemName} alternatives.
                    </p>

                    <Link
                      prefetch={false}
                      className="fake-a-alt"
                      title={`All apps with ${name} feature`}
                      href={
                        feature.linkHref
                          ? feature.linkHref
                          : `/feature/${feature.urlName}`
                      }
                    >
                      Browse all apps with this {feature.type.toLowerCase()}
                    </Link>
                  </>
                )}
                {onVote && (
                  <>
                    <Spacer space={2}></Spacer>
                    <strong>
                      Is this an important {feature.type.toLowerCase()} for{" "}
                      {itemName}?
                    </strong>
                    <div className={styles.voteContainer}>
                      <UpDownVoter
                        upVote={hasVoted === "agree"}
                        downVote={hasVoted === "disagree"}
                        upVoteText={`Yes, it's an important ${feature.type.toLowerCase()}`}
                        downVoteText={`No, it's not an important ${feature.type.toLowerCase()}`}
                        direction="row"
                        upElement={
                          <>
                            <IconWithBorder customStyle={["positive", "small"]}>
                              <FaThumbsUp size={12} />
                            </IconWithBorder>
                          </>
                        }
                        downElement={
                          <>
                            <IconWithBorder customStyle={["negative", "small"]}>
                              <FaThumbsDown size={12} />
                            </IconWithBorder>
                          </>
                        }
                        type={"Feature"}
                        toggleVote={toggleVote}
                        isLoggedIn={isLoggedIn}
                      />
                    </div>
                  </>
                )}
              </div>
            );
          }}
        >
          <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            className={`f-h-center ${styles.title}`}
          >
            {featureIcon}
            &nbsp;
            <span
              className={classNames(styles.titleText, {
                [styles.hot]: feature.hot || feature.shortDescription,
              })}
            >
              {isIntegration && featureNameTransform === "remove-integration"
                ? feature.name.replace(/ integration/i, "")
                : feature.name}
            </span>
            <span
              className={classNames(
                styles.toggle,
                styles["toggle-" + popoverOpen],
                "transitionDefault",
              )}
            >
              <FaChevronDown></FaChevronDown>
            </span>
          </span>
        </Popover>
      </li>
    </>
  );
};
export default HighlightFeatureListItem;

"use client";

import classNames from "classnames";
import React from "react";
import { FaTags } from "react-icons/fa";

import MaxHeightWithFade from "~/c/MaxHeightWithFade";
import Heading from "~/components/Heading";
import { arrayToCommaSeparatedWithSuffix } from "~/helpers/arrayHelper";
import { CommonPhrases } from "~/helpers/phrases/common";
import { FeatureWithUserInfo, Language, Tag, VoteType } from "~/typings/types";

import HighlightFeatureListItem from "./HighlightFeatureListItem";
import styles from "./HighlightFeatures.module.scss";
import TagLinkList from "./TagLinkList";

import Box from "~/c/Box";
import { useDataCollector } from "~/c/AppRouter/hooks/useDataCollector";
import { DataCollectorWrapper } from "~/c/AppRouter/DataCollectorWrapper";

export type HighlightFeaturesSize = "normal" | "large";

type HighlightFeaturesProps = {
  features: FeatureWithUserInfo[];
  properties: FeatureWithUserInfo[];
  otherFeatures?: Tag[];
  languages?: Language[];
  browserLanguages?: string[];
  itemName: string;
  currentItemId: string;
  size?: HighlightFeaturesSize;
  showHeader?: boolean;
  gameSeriesDesc?: string;
  showNoFeatureBox?: boolean;
  isMobile: boolean;
  groupIntegrations?: boolean;
};

const processFeatures = (items: FeatureWithUserInfo[]) => {
  if (!items?.length) return { topItems: [], remainingItems: [] };
  const totalLikes = items.reduce((sum, item) => sum + item.likes, 0);

  let numberOfTopItems = 0;
  if (totalLikes > 150) numberOfTopItems = 26;
  else if (totalLikes > 100) numberOfTopItems = 20;
  else if (totalLikes > 75) numberOfTopItems = 14;
  else if (totalLikes > 50) numberOfTopItems = 10;
  else if (totalLikes > 20) numberOfTopItems = 6;
  else if (totalLikes > 10) numberOfTopItems = 4;

  const topItems = items
    .sort((a, b) => b.likes - a.likes)
    .slice(0, numberOfTopItems)
    .map((item) => ({ ...item, hot: true }));

  const remainingItems = items
    .filter((item) => !topItems.some((topItem) => topItem.name === item.name))
    .sort((a, b) => {
      if (a.shortDescription && !b.shortDescription) return -1;
      if (!a.shortDescription && b.shortDescription) return 1;
      return b.likes - a.likes;
    });

  return { topItems, remainingItems };
};

const HighlightFeatures = (props: HighlightFeaturesProps) => {
  const {
    languages,
    browserLanguages,
    itemName,
    //suggestFeatures,
    size = "normal",
    otherFeatures,
    showHeader = true,
    gameSeriesDesc,
    showNoFeatureBox = false,
    isMobile,
    properties,
    currentItemId,
    groupIntegrations = true,
  } = props;

  let { features } = props;

  const minIntegrations = 4;

  const topBrowserLanguages = browserLanguages && browserLanguages.slice(0, 3);

  const showLanguageFeature =
    languages &&
    languages.length > 0 &&
    topBrowserLanguages &&
    topBrowserLanguages.filter((x) => x !== "en").length > 0;

  if (showLanguageFeature && features.length === 9) {
    features.pop();
  }

  let showSupportedLanguages =
    showLanguageFeature &&
    topBrowserLanguages.flatMap((x) => {
      const lang = languages.find((y) => y.isoCode == x);
      if (lang) return lang.name;
      return [];
    });

  if (
    showSupportedLanguages &&
    showSupportedLanguages.filter((x) => x !== "English").length === 0
  ) {
    showSupportedLanguages = undefined;
  }

  const languageTitle =
    showSupportedLanguages && showSupportedLanguages.join(", ");

  let languageDesc =
    showSupportedLanguages &&
    `${itemName} supports ${languageTitle} and ${
      languages.length - showSupportedLanguages.length
    } other languages.`;

  if (languages && languages.length < 4) {
    languageDesc =
      showSupportedLanguages &&
      `${itemName} supports ${arrayToCommaSeparatedWithSuffix(
        languages.map((x) => x.name),
        " and",
      )}.`;
  }

  const showFeatureGrid =
    (showSupportedLanguages && showSupportedLanguages.length > 0) ||
    (features && features.length > 0);

  let supportedLanguageFeature;

  if (showSupportedLanguages && showSupportedLanguages.length > 0) {
    supportedLanguageFeature = {
      name: languageTitle,
      shortDescription: languageDesc,
    } as FeatureWithUserInfo;
  }

  let gameSeriesFeature;

  if (gameSeriesDesc) {
    gameSeriesFeature = {
      name: "Game Series",
      shortDescription: gameSeriesDesc,
    } as FeatureWithUserInfo;
  }

  if (features && features.length > 5 && size == "normal") {
    let maxHighlight = isMobile ? 8 : 12;

    const newFeatures: Tag[] = features
      .slice(maxHighlight, features.length)
      .map((x) => ({
        name: x.name,
        urlName: x.urlName,
        type: "Feature",
      }));

    if (otherFeatures) {
      otherFeatures.unshift(...newFeatures);
    }

    if (supportedLanguageFeature) {
      maxHighlight = maxHighlight - 1;
    }

    features = features.slice(0, maxHighlight);
  }

  const integrations = features?.filter((f) =>
    f.urlName.toLowerCase().endsWith("-integration"),
  );

  const showIntegrationHeader =
    integrations?.length >= minIntegrations && groupIntegrations;

  if (showIntegrationHeader) {
    features = features.filter(
      (f) => !f.urlName.toLowerCase().endsWith("-integration"),
    );
  }

  const { topItems: topFeatures, remainingItems: remainingFeatures } =
    processFeatures(features);
  const { topItems: topProperties, remainingItems: remainingProperties } =
    processFeatures(properties);
  const { topItems: topIntegrations, remainingItems: remainingIntegrations } =
    integrations?.length >= minIntegrations
      ? processFeatures(integrations)
      : { topItems: [], remainingItems: [] };

  const sortedFeatures = [
    ...(topFeatures?.filter(Boolean) || []),
    ...(remainingFeatures?.filter(Boolean) || []),
  ];
  const sortedProperties = [
    ...(topProperties?.filter(Boolean) || []),
    ...(remainingProperties?.filter(Boolean) || []),
  ];
  const sortedIntegrations = [
    ...(topIntegrations?.filter(Boolean) || []),
    ...(remainingIntegrations?.filter(Boolean) || []),
  ];

  const hasProperties = properties && properties.length > 0;

  const { showDataCollector, renderDCWidget, closeDataCollector } =
    useDataCollector(currentItemId);

  const handleFeatureVote = async (
    feature: FeatureWithUserInfo,
    voteState: VoteType,
  ) => {
    const voteBody = {
      name: feature.name,
      urlName: feature.urlName,
      opinion: voteState === "agree" ? 1 : 0,
    };

    showDataCollector({
      widgets: ["SilentAction"],
      silentAction: {
        url: `/items/${currentItemId}/features/vote/`,
        body: voteBody,
        type: "FeatureVote",
      },
      key: currentItemId + feature.urlName,
    });
  };

  return (
    <>
      {showHeader && sortedProperties.length === 0 && (
        <Heading
          style={{ marginTop: "16px", marginBottom: "0" }}
          metaHeading={true}
          element={size == "normal" ? "h4" : "h3"}
        >
          {sortedFeatures.length > 10 && "Top "} {itemName}{" "}
          {CommonPhrases.en.features}
        </Heading>
      )}
      <div className={`${styles.featureContainer}`}>
        <MaxHeightWithFade
          showMoreText={`Show all ${itemName} features`}
          maxHeight={340}
        >
          {hasProperties && (
            <>
              <Heading
                style={{ marginTop: "16px", marginBottom: "0" }}
                metaHeading={true}
                element="h3"
              >
                {CommonPhrases.en.properties}
              </Heading>
              <ol
                className={classNames(
                  styles.featureGrid,
                  styles["featureGrid-" + size],
                )}
              >
                {sortedProperties &&
                  sortedProperties.map((p, index) => {
                    return (
                      <HighlightFeatureListItem
                        key={index}
                        feature={p}
                        itemName={itemName}
                        size={size}
                        currentItemId={currentItemId}
                        onVote={(voteState) => handleFeatureVote(p, voteState)}
                      />
                    );
                  })}
              </ol>
            </>
          )}

          {showIntegrationHeader && (
            <>
              <Heading
                style={{ marginTop: "16px", marginBottom: "0" }}
                metaHeading={true}
                element="h3"
              >
                Integrations
              </Heading>
              <ol
                className={classNames(
                  styles.featureGrid,
                  styles["featureGrid-" + size],
                )}
              >
                {sortedIntegrations.map((integration, index) => (
                  <HighlightFeatureListItem
                    key={index}
                    feature={integration}
                    itemName={itemName}
                    size={size}
                    currentItemId={currentItemId}
                    featureNameTransform="remove-integration"
                    onVote={(voteState) =>
                      handleFeatureVote(integration, voteState)
                    }
                  />
                ))}
              </ol>
            </>
          )}

          {showFeatureGrid ? (
            <>
              {(hasProperties ||
                sortedIntegrations.length >= minIntegrations) && (
                <Heading
                  style={{ marginTop: "16px", marginBottom: "0" }}
                  metaHeading={true}
                  element="h3"
                >
                  {CommonPhrases.en.features}
                </Heading>
              )}
              <ol
                className={classNames(
                  styles.featureGrid,
                  styles["featureGrid-" + size],
                )}
                style={{ marginTop: !hasProperties && "16px" }}
              >
                {gameSeriesFeature && (
                  <HighlightFeatureListItem
                    key={-1}
                    feature={gameSeriesFeature}
                    size={size}
                    currentItemId={currentItemId}
                  />
                )}
                {showSupportedLanguages &&
                  showSupportedLanguages.length > 0 && (
                    <HighlightFeatureListItem
                      key={-1}
                      feature={supportedLanguageFeature}
                      size={size}
                      currentItemId={currentItemId}
                    />
                  )}

                {sortedFeatures &&
                  sortedFeatures.map((f, index) => {
                    return (
                      <HighlightFeatureListItem
                        key={index}
                        feature={f}
                        itemName={itemName}
                        size={size}
                        currentItemId={currentItemId}
                        onVote={(voteState) => handleFeatureVote(f, voteState)}
                      />
                    );
                  })}
              </ol>
            </>
          ) : (
            <>
              {showNoFeatureBox && (
                <Box customClass="noDataBox">
                  <FaTags></FaTags>
                  <div>No features, maybe you want to suggest one?</div>
                </Box>
              )}
            </>
          )}
          {otherFeatures && otherFeatures.length > 0 && (
            <div className={styles.otherFeatures}>
              <Heading metaHeading={true} element="h3">
                &nbsp;{CommonPhrases.en.tags}
              </Heading>

              <TagLinkList tags={otherFeatures}></TagLinkList>
            </div>
          )}
        </MaxHeightWithFade>
      </div>
      {renderDCWidget && (
        <DataCollectorWrapper
          renderDCWidget={renderDCWidget}
          mainItemName={itemName}
          onClose={closeDataCollector}
          key={renderDCWidget.key}
        />
      )}
    </>
  );
};
export default HighlightFeatures;

"use client";

import React, { useState } from "react";
import AppBadgeRow from "~/c/AppCommon/AppBadgeRow";
import AppIcon from "~/c/AppCommon/AppIcon";
import RenderMarkdownWithLinks from "~/c/Common/RenderMarkdownWithLinks";
import Heading from "~/c/Heading";
import ImageCollectionModern from "~/c/ImageCollection/ImageCollection-modern";

import { getUrl, UrlEntity } from "~/helpers/urls";
import {
  HighlightedFeature,
  ItemForCustomList,
  ItemForList,
  Tag,
} from "~/typings/types";
import { AppLink } from "~/c/AppItem/AppLink";
import { ModernLikeButton } from "~/c/AppCommon/ModernLikeButton";
import { cn } from "@/lib/utils";

import { AppItemExpander } from "~/c/AppItem/AppItemExpander";
import { AppItemExpandableContent } from "~/c/AppItem/AppItemExpandableContent";
import HighlightFeatures from "~/c/AppCommon/HighlightFeatures";
import { manualHtmlDecode } from "~/helpers/stringHelpers";
import { getGameSeriesDesc } from "~/helpers/commonHelper";
import ExpandedBadgeRow from "~/c/AppItem/ExpandedBadgeRow";
import AppPromotion from "~/c/AppCommon/AppPromotion";
import AppAlertList from "~/c/AppCommon/AppAlertList";
import { ISilentAction, WidgetTypes } from "~/c/DataCollector/types";
import IconWithBorder from "~/c/Common/IconWithBorder";
import CopyDirectLink from "~/c/Common/CopyDirectLink";
import { FaChevronCircleRight, FaThList } from "react-icons/fa";
import Link from "next/link";
import Button from "~/c/Button";

export type AppItemContainerStyle = "expandedBox" | "compactBox";

export const isItemForList = (
  item: ItemForCustomList | ItemForList,
): item is ItemForList => {
  // A more meaningful check to distinguish ItemForList from ItemForCustomList
  return "topAlternatives" in item || "appTypes" in item;
};

interface AppItemProps {
  app: ItemForList | ItemForCustomList;
  highlightFeatures?: HighlightedFeature[];
  features?: Tag[];
  linkTo: UrlEntity;
  browserLanguages?: string[];
  expandBar?: React.ReactNode;
  expandBelowContent?: React.ReactNode;
  isLoggedIn?: boolean;
  openDataCollector?: (
    itemId: string,
    widgets: WidgetTypes[],
    silentAction?: ISilentAction,
  ) => void;
  directLinkUrl?: string;
  directLinkMessage?: string;
  hideItemAlerts?: string[];
  showFeatures?: boolean;
  isDefaultExpanded?: boolean;
  alternativeId?: string;
  isSponsoredAlternative?: boolean;
  isHighlighted?: boolean;
  isMobile?: boolean;
  showAboutButton?: boolean;
}

const AppItem = ({
  app,
  linkTo,
  highlightFeatures = [],
  features = [],
  browserLanguages,
  expandBar,
  expandBelowContent,
  isLoggedIn,
  openDataCollector,
  directLinkUrl,
  directLinkMessage,
  hideItemAlerts = [],
  showFeatures = true,
  isDefaultExpanded = false,
  alternativeId,
  isHighlighted = false,
  isSponsoredAlternative = false,
  isMobile = false,
  showAboutButton = false,
}: AppItemProps) => {
  const [currentExpanded, setCurrentExpanded] = useState(isDefaultExpanded);

  const toggleExpanded = () => {
    const event = new CustomEvent("app-item-expanded", {
      detail: { itemId: app.id, type: "toggle" },
      bubbles: true,
    });
    document.dispatchEvent(event);
  };

  const handleExpandedChange = (expanded: boolean) => {
    setCurrentExpanded(expanded);
  };

  const screenshots =
    app.images && app.images.filter((image) => image.type === "Screenshot");

  const icon = app.images && app.images.find((image) => image.type === "Icon");

  const decodedName = manualHtmlDecode(app.name);

  const gameSeriesMetaTag =
    app &&
    app.metaTags &&
    app.metaTags.find((metaTag) => metaTag.type === "Game Series");

  const gameSeriesDesc = getGameSeriesDesc(gameSeriesMetaTag, decodedName);

  const combinedFeatures = [...highlightFeatures, ...features];

  return (
    <AppItemExpandableContent
      itemId={app.id}
      isDefaultExpanded={isDefaultExpanded}
      onExpandedChange={handleExpandedChange}
    >
      <div
        className={cn(
          "flex flex-col group/app-item",
          isHighlighted && "border border-gray-300 rounded-[15px]",
        )}
        id={`app-item-${app.id}`}
      >
        {/* Main App Item Box */}
        <div
          className={cn(
            "flex flex-col relative",
            "bg-custom-gray50 border border-brand-light4",
            !expandBar ? "rounded-[15px]" : "rounded-t-[15px]",
          )}
        >
          {/* Top row with icon, info, and screenshots */}
          <div className="flex flex-col md:flex-row">
            {/* Left column for icon, info, and new row */}
            <div className="flex flex-col md:flex-grow">
              {/* Icon and Info row */}
              <div className="flex flex-col md:flex-row">
                {/* Desktop Icon */}
                <div
                  data-testid="app-header"
                  className="hidden lg:block order-1 static pl-3 self-start mt-5"
                >
                  <AppLink linkTo={linkTo} app={app}>
                    <div className="border border-brand-light4 rounded-[15px] leading-[0] aspect-square w-[150px] h-[150px] flex items-center justify-center">
                      <AppIcon
                        imgObject={icon}
                        appName={app.name}
                        dimension={140}
                        className="rounded-[15px] w-[126px] h-[126px]"
                      />
                    </div>
                  </AppLink>
                </div>

                {/* Info Section */}
                <div className="order-3 self-start pt-0 p-2 pb-4 md:pb-0 grid gap-2 md:order-2 md:p-5 md:pr-5 md:flex-grow lg:grid-cols-[auto,1fr]">
                  {/* Heading Section - First Column */}
                  <div className="flex flex-col w-full gap-3">
                    <div className="flex items-center gap-3">
                      {/* Mobile Icon */}
                      <div className="flex items-center lg:hidden">
                        <AppLink linkTo={linkTo} app={app}>
                          <div className="aspect-square">
                            <AppIcon
                              imgObject={icon}
                              appName={app.name}
                              dimension={70}
                              className="w-[48px] md:w-[70px]"
                            />
                          </div>
                        </AppLink>
                      </div>
                      {/* Title */}
                      <div className="min-w-[180px] md:min-w-0">
                        <AppLink linkTo={linkTo} app={app}>
                          <Heading
                            dynamicSize={15}
                            className={
                              expandBelowContent || isSponsoredAlternative
                                ? "!text-2xl md:!text-3xl"
                                : "!text-2xl"
                            }
                            element={"h2"}
                          >
                            {decodedName}
                          </Heading>
                        </AppLink>
                      </div>
                      {/* Like Button */}
                      <div
                        id="like-button-container"
                        className="flex-shrink-0 flex-grow ml-auto"
                      >
                        {linkTo === "external" || isSponsoredAlternative ? (
                          <span className="ml-2 border border-brand-light4 text-meta-light text-[0.7em] px-[0.325em] py-[0.2em] rounded inline-block">
                            Sponsored
                          </span>
                        ) : (
                          <ModernLikeButton
                            serverLikes={app.likes}
                            urlName={app.urlName}
                            name={decodedName}
                            itemId={app.id}
                            onCustomDCEvent={() => {
                              if (alternativeId) {
                                return openDataCollector(
                                  app.id,
                                  ["SilentAction", "Comment", "Features"],
                                  {
                                    url: `/items/alternative/vote/`,
                                    type: "AlternativeVote",
                                    body: {
                                      alternativeId: alternativeId,
                                      voteType: "agree",
                                    },
                                  },
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Tools - Second Column */}
                  <div className="flex justify-end items-center">
                    {linkTo !== "external" && (
                      <div className="flex items-center gap-2">
                        {isLoggedIn && (
                          <div className="opacity-0 group-hover/app-item:opacity-100 transition-opacity duration-200">
                            <IconWithBorder customStyle={["small"]}>
                              <FaThList
                                size={14}
                                title={`Add ${decodedName} to a list`}
                                onClick={() =>
                                  openDataCollector(app.id, ["AddToList"])
                                }
                              ></FaThList>
                            </IconWithBorder>
                          </div>
                        )}
                        {directLinkUrl && directLinkMessage && (
                          <div className="opacity-0 group-hover/app-item:opacity-100 transition-opacity duration-200">
                            <IconWithBorder customStyle={["small"]}>
                              <CopyDirectLink
                                iconSize="14px"
                                url={directLinkUrl}
                                toastBody={directLinkMessage}
                              ></CopyDirectLink>
                            </IconWithBorder>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Description */}
                  <div
                    className={cn(
                      "col-span-2 overflow-hidden",
                      currentExpanded ? "line-clamp-3" : "line-clamp-2",
                    )}
                    id="app-description"
                  >
                    <RenderMarkdownWithLinks
                      md={app.shortDescriptionOrTagLine || app.description}
                      format="md_Compact"
                    />
                  </div>
                  {isItemForList(app) && alternativeId && (
                    <Link
                      className={`meta-medium !underline`}
                      href={getUrl("app-alternatives", [app.urlName])}
                    >
                      {app.alternatives} {decodedName} alternatives
                    </Link>
                  )}
                  {/* Badges - Collapsed View */}
                  <div
                    className={cn(
                      "col-span-2 overflow-hidden",
                      currentExpanded ? "hidden" : "",
                    )}
                    id="collapsed-badge-row"
                  >
                    <AppBadgeRow
                      licenseCost={app.licenseCost}
                      licenseModel={app.licenseModel}
                      appTypes={app.appTypes && app.appTypes.slice(0, 1)}
                      platforms={app.platforms}
                      platformRows={1}
                      platformsOnNewLineOption="never"
                      enableShowMore={false}
                    />
                    {app.affiliatePromotion && (
                      <>
                        <AppPromotion
                          urlName={app.urlName}
                          promotion={app.affiliatePromotion}
                        ></AppPromotion>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* New row below icon and info section */}
              <div
                id="expanded-badge-row"
                className={cn(
                  "flex px-2 md:px-5 py-3",
                  currentExpanded ? "" : "hidden",
                )}
              >
                {isItemForList(app) && (
                  <>
                    <ExpandedBadgeRow app={app} />
                  </>
                )}
              </div>
            </div>

            {/* Screenshots Section - Order changed for mobile */}
            {screenshots.length > 0 ? (
              <div className="order-[-1] md:order-3 relative h-auto min-h-[212px] justify-center p-0 md:p-2 md:min-h-[197px] md:self-start">
                <ImageCollectionModern
                  imageSize="small"
                  altPrefix={app.name}
                  itemId={app.id}
                  screenshots={screenshots.slice(0, 12)}
                  type="hero"
                />
                <div
                  id="expanded-thumbnails-row"
                  className={cn(currentExpanded ? "" : "hidden")}
                >
                  <ImageCollectionModern
                    imageSize="smaller"
                    altPrefix={decodedName}
                    itemId={app.id}
                    screenshots={screenshots}
                    type={"thumbnail"}
                    showOverlay={true}
                  ></ImageCollectionModern>
                  {showAboutButton && !isMobile && (
                    <div className="mt-4">
                      <AppLink linkTo={linkTo} app={app}>
                        <Button
                          style={{ display: "flex", alignItems: "center" }}
                          look={"transparent"}
                          fill={true}
                        >
                          {linkTo === "external" ? "Visit" : "More about"}{" "}
                          {decodedName}
                          &nbsp;&nbsp;
                          <FaChevronCircleRight></FaChevronCircleRight>
                        </Button>
                      </AppLink>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="order-[-1] md:order-3 relative p-2"></div>
            )}
          </div>

          {/* Expandable Content */}
          <div
            className={cn("mx-2 md:mx-5 mb-8", currentExpanded ? "" : "hidden")}
            id="app-expandable-content"
          >
            {isItemForList(app) && (
              <>
                {app.itemAlerts && app.itemAlerts.length > 0 && (
                  <AppAlertList
                    itemAlerts={app.itemAlerts}
                    hideItemAlerts={hideItemAlerts}
                  />
                )}
                {showFeatures && combinedFeatures.length > 0 && (
                  <HighlightFeatures
                    isMobile={false}
                    size="normal"
                    features={combinedFeatures.filter(
                      (feature) => feature.type === "Feature",
                    )}
                    properties={combinedFeatures.filter(
                      (feature) => feature.type === "Property",
                    )}
                    languages={app.supportedLanguages}
                    gameSeriesDesc={gameSeriesDesc}
                    browserLanguages={browserLanguages}
                    itemName={decodedName}
                    currentItemId={app.id}
                    groupIntegrations={false}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {/* Meta Section Bar */}
        {isItemForList(app) && expandBar && (
          <div
            className={cn(
              "bg-brand-light10 border border-t-0 border-brand-light4 px-5 py-4 expand-bar",
              (!currentExpanded || !expandBelowContent) && "rounded-b-[15px]",
            )}
          >
            <div className="flex flex-row items-center relative">
              <div className="flex flex-grow flex-row justify-center md:justify-start md:mb-0">
                {expandBar}
              </div>
              <div className="flex md:self-auto justify-center absolute left-1/2 -translate-x-1/2 md:right-0 md:translate-x-0 md:static top-[-26px]">
                <AppItemExpander
                  itemId={app.id}
                  isExpanded={currentExpanded}
                  onToggle={toggleExpanded}
                  hideWhenExpanded={expandBelowContent ? true : false}
                />
              </div>
            </div>
          </div>
        )}

        {/* Content below expand bar */}
        {expandBelowContent && (
          <div
            className={cn(
              "bg-custom-gray50 border border-t-0 border-brand-light4 rounded-b-[15px] px-2 py-2 md:px-5 md:py-5 mt-0",
              currentExpanded ? "" : "hidden",
            )}
            id="app-expandable-content-below"
          >
            {expandBelowContent}
            <div className="flex justify-end mt-8">
              <AppItemExpander
                itemId={app.id}
                isExpanded={currentExpanded}
                onToggle={toggleExpanded}
                hideWhenExpanded={false}
                showTextOnMobile={true}
              />
            </div>
          </div>
        )}
      </div>
    </AppItemExpandableContent>
  );
};

export default AppItem;

import React from "react";

import { FaInfoCircle } from "react-icons/fa"; // import FaInfoCircle from its location
import styles from "./ExpandedBadgeRow.module.scss"; // import styles from its location
import Heading from "~/c/Heading";
import { plural } from "~/helpers/plurals";
import { getUrl } from "~/helpers/urls";
import Link from "next/link";
import { GetAppTypeAndSuffix } from "~/helpers/appListHelper";

const ExpandedBadgeRow = ({ app, highlightFeatures = undefined }) => {
  return (
    <div className={styles.badgesAbout}>
      {app.licenseCost && app.licenseModel && (
        <div className={`${styles.licenseBadges}`}>
          <Heading metaHeading={true} element="h4">
            License model
          </Heading>
          <ul className={`badges`}>
            <li
              className={`badge-license ${
                app.licenseCost && app.licenseCost.toLowerCase()
              }`}
            >
              <span className="large">
                {app.licenseCost} • {app.licenseModel}
              </span>
            </li>
          </ul>
        </div>
      )}
      {app.appTypes && app.appTypes.length > 0 && (
        <div className={`${styles.appTypeBadges}`}>
          <Heading metaHeading={true} element="h4">
            Application {plural("type", app.appTypes.length)}
          </Heading>
          <ul className={`badges `}>
            {app.appTypes.slice(0, 2).map((appType, index) => {
              const nextUrl = appType.linkHref
                ? appType.linkHref
                : getUrl("feature", [appType.urlName]);
              return (
                <li className="badge-more-important" key={index}>
                  <Link prefetch={false} className="large" href={nextUrl}>
                    {GetAppTypeAndSuffix(appType.appType, appType.titleSuffix)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {app.platforms && (
        <div className={`${styles.platformBadges}`}>
          <Heading metaHeading={true} element="h4">
            Platforms
          </Heading>
          <ul data-testid="platform-row" className={`badges`}>
            <>
              {app.platforms.map((platform) => {
                const haveNoteText = app.platformsWithNote?.find(
                  (x) => x.name === platform.name,
                )?.note;

                return (
                  <li key={platform.name}>
                    <span className="large">
                      {platform.name}
                      {haveNoteText && (
                        <>
                          &nbsp;&nbsp;
                          <FaInfoCircle
                            style={{ margin: 0 }}
                            title={haveNoteText}
                            color="var(--metaLight)"
                          ></FaInfoCircle>
                        </>
                      )}
                    </span>
                  </li>
                );
              })}
            </>
          </ul>
        </div>
      )}
      {highlightFeatures}
    </div>
  );
};

export default ExpandedBadgeRow;

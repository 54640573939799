import {
  callAPIClientSide,
  callAPIServerSide,
  callPlainAPI,
} from "~/helpers/api";
import { revalidateAPIData } from "~/helpers/approuter/api";
import { UrlNameAndItemId } from "~/typings/types";

export const clearItemCacheServerSide = async (
  accessToken: string,
  urlName: string,
  itemId?: string,
) => {
  await callAPIServerSide(
    `/system/clear-item-cache/`,
    accessToken,
    null,
    "POST",
    {
      UrlName: urlName,
      ItemId: itemId ? itemId : "",
    },
  ).catch((err) => {
    console.error(`Failed to clear the cache for ${urlName}: ${err}`);
  });

  await revalidateAPIData("about-" + urlName);
};

export const clearCacheClientSide = async (
  cc: UrlNameAndItemId,
  isCrewAdmin: boolean,
) => {
  const isServer = typeof window === "undefined";

  if (isServer) {
    throw new Error("Clearing cache is not supported on the server.");
  }

  try {
    await callAPIClientSide(`/system/clear-item-cache/`, null, "POST", "0", cc);
  } catch (err) {
    console.error(`Failed to clear the cache for ${cc.urlName}: ${err}`);
  }

  if (cc.itemId && isCrewAdmin) {
    try {
      await callPlainAPI(`/api/tools/update-opinion-rank/?itemId=${cc.itemId}`);
    } catch (err) {
      console.error(`Failed to clear the cache for ${cc.urlName}: ${err}`);
    }
  }

  await revalidateAPIData("about-" + cc.urlName);

  return "Cache cleared.";
};

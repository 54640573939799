import React from "react";

import styles from "./NoScreenshot.module.scss";
import { BiImageAdd } from "react-icons/bi";

import Link from "next/link";
import { sizeType } from "~/typings/types";

interface NoScreenshotProps {
  itemId: string;
  showAddButton?: boolean;
  size?: sizeType;
  noText?: boolean;
}

const NoScreenshot = (props: NoScreenshotProps) => {
  const { itemId, size, noText } = props;

  let text = "Add a screenshot";

  if (size === "smaller") {
    text = "";
  }

  return (
    <div className={`${styles.noScreenshot} ${styles[size]}`}>
      {!noText ? (
        <Link prefetch={false} href={`/manage-item/?id=${itemId}`}>
          <BiImageAdd /> {text}
        </Link>
      ) : (
        <Link prefetch={false} href={`/manage-item/?id=${itemId}`}>
          <BiImageAdd />
        </Link>
      )}
    </div>
  );

  // return (
  //   <div className={styles.noScreenshot}>
  //     {rootContext.isInRole("freezer") ? (
  //       <Link href={`/manage-item/?id=${itemId}`}>
  //         <FaPlusCircle></FaPlusCircle>&nbsp;Add a screenshot
  //       </Link>
  //     ) : (
  //       <span>No screenshot</span>
  //     )}
  //   </div>
  // );
};
export default NoScreenshot;

"use client";

import React, { useEffect, useState } from "react";
import { ItemImage, sizeType } from "~/typings/types";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ImageCollection-modern.module.scss";
import { FaExpandAlt } from "react-icons/fa";
import ImageCollectionImage from "~/c/ImageCollection/ImageCollectionImage";
import LightboxModal from "~/c/ImageCollection/LightboxModal";
import NoScreenshot from "~/c/ImageCollection/NoScreenshot";
import { AppItemContainerStyle } from "~/c/AppItem/AppItem";
import classNames from "classnames";

export type ImageCollectionType = "hero" | "thumbnail";

type ImageCollectionModernProps = {
  screenshots: ItemImage[];
  altPrefix: string;
  containerStyle?: AppItemContainerStyle;
  itemId: string;
  imageSize: sizeType;
  type?: ImageCollectionType;
  showOverlay?: boolean;
  enableLightbox?: boolean;
};

const ImageCollectionModern = (props: ImageCollectionModernProps) => {
  const {
    screenshots,
    altPrefix,
    itemId,
    imageSize,
    type,
    showOverlay,
    containerStyle,
    enableLightbox = true,
  } = props;

  //const [startIndex, setStartIndex] = useState(0);

  const [screenshotModalIsOpen, setScreenshotModalIsOpen] = useState(false);
  const [screenshotFileName, setScreenshotFileName] = useState("");

  let showNumberOfImages = type === "hero" ? 1 : 3;
  let aspectType = "landscape-new";
  const displayScreens = [...screenshots];
  const screenshotCount = screenshots.length;

  let containerClass = "";

  if (containerStyle === "expandedBox") {
    containerClass = styles.expandedBox;
  } else if (containerStyle === "compactBox") {
    containerClass = styles.compactBox;
  }

  const openMediaModal = (fileName: string) => {
    // Give images 200ms extra to preload
    const delay = 100;

    setTimeout(() => {
      setScreenshotModalIsOpen(true);
      setScreenshotFileName(fileName);
    }, delay);
  };

  const preloadImage = (url: string) => {
    const preloadLink = document.createElement("link");
    preloadLink.href = url;
    preloadLink.rel = "preload";
    preloadLink.as = "image";
    document.head.appendChild(preloadLink);
  };

  const numberOfPortraitImages = (data: ItemImage[]) => {
    let portraitImageCount = 0;

    data.forEach((item) => {
      // Iterate through the signedImages array

      item.signedImages.forEach((image) => {
        // Check if the size property is equal to "portrait"
        if (image.size.toLowerCase() === "portrait-new") {
          portraitImageCount++;
        }
      });
    });

    return portraitImageCount;
  };

  const portraitCount = numberOfPortraitImages(screenshots);

  const isPortraitMode =
    screenshotCount > 0 &&
    portraitCount == screenshotCount &&
    portraitCount > 1;

  let noScreenshots;

  let showNumberOverlay = false;

  if (isPortraitMode) {
    showNumberOfImages = 3;

    if (showOverlay && screenshotCount > showNumberOfImages) {
      showNumberOverlay = true;
    }
  } else {
    showNumberOfImages = screenshotCount > 0 ? 1 : 0;
  }

  const skipScreen = type === "thumbnail" ? 1 : 0;

  if (type === "thumbnail" && screenshotCount > 0) {
    showNumberOfImages = 3;

    if (screenshotCount > showNumberOfImages && showOverlay) {
      showNumberOverlay = true;
    }

    const emptySpaces = 3 - displayScreens.length + skipScreen;

    if (emptySpaces > 0) {
      noScreenshots = Array(emptySpaces)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            data-testid="no-screens"
            className={`${styles.thumbnail} ${containerClass} ${styles.noScreens} ${styles[imageSize]}`}
          >
            <NoScreenshot
              noText={index < emptySpaces - 1}
              size={imageSize}
              itemId={itemId}
            />
          </div>
        ));
    }
  }

  useEffect(() => {
    return () => {
      const preloadLinks = document.head.querySelectorAll(
        'link[rel="preload"][as="image"]',
      );
      preloadLinks.forEach((link) => link.remove());
    };
  }, []);

  // if ((isPortraitMode || screenshotCount < 3) && type === "thumbnail") {
  //   return null;
  // }

  const finalDisplayScreens = displayScreens.slice(
    skipScreen,
    showNumberOfImages + skipScreen,
  );

  return (
    <>
      <div
        data-testid={`image-collection-modern-${type}`}
        className={`${styles.thumbnailWrapper}`}
        data-screenshot-count={[...finalDisplayScreens].length}
        onMouseEnter={() => {
          finalDisplayScreens.map(({ signedImages }) => {
            const signedHiresScreenshot =
              signedImages &&
              signedImages.find(
                (x) => x.size === "1200x1200" || x.size === "2400x2400",
              );
            if (signedHiresScreenshot) {
              preloadImage(signedHiresScreenshot.signedURL);
            }
          });
        }}
      >
        {!(
          (isPortraitMode || finalDisplayScreens.length < 1) &&
          type === "thumbnail"
        ) && (
          <div
            className={classNames(
              styles.thumbnail,
              styles[imageSize],
              { [styles.portrait]: showNumberOfImages > 1 && type === "hero" },
              {
                [styles.landscape]: showNumberOfImages === 1 && type === "hero",
              },
              containerClass,
            )}
          >
            {finalDisplayScreens &&
              [...finalDisplayScreens].map((screen, index) => {
                aspectType = "landscape-new";

                if (type === "hero" && showNumberOfImages > 1) {
                  aspectType = screen?.signedImages?.find(
                    (x) => x.size === "portrait-new",
                  )
                    ? "portrait-new"
                    : "landscape-new";
                }

                const description = screen.description
                  ? screen.description
                  : altPrefix + " screenshot " + (index + 1);

                if (screen.embedUrl) {
                  return (
                    <div key={screen.fileName} style={{ width: "427px" }}>
                      <iframe
                        title={screen.description}
                        src={screen.embedUrl}
                        allowFullScreen={true}
                        width="427"
                        height="240"
                      ></iframe>
                    </div>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      <div
                        title={description}
                        key={screen.fileName}
                        onClick={() =>
                          enableLightbox && openMediaModal(screen.fileName)
                        }
                      >
                        <ImageCollectionImage
                          isHero={false}
                          aspectType={aspectType}
                          screenshot={screen}
                          screenshotsCount={displayScreens.length}
                          description={description}
                          imageSize={imageSize}
                          fill={imageSize !== "smaller" ? true : false}
                          showOverlay={
                            index === showNumberOfImages - 1 &&
                            showNumberOverlay
                          }
                        />
                        {enableLightbox && (
                          <span
                            onClick={() => {
                              openMediaModal(screen.fileName);
                            }}
                            className={styles.expandImage}
                          >
                            <FaExpandAlt color="#000" size={16}></FaExpandAlt>
                          </span>
                        )}
                      </div>
                    </React.Fragment>
                  );
                }
              })}
          </div>
        )}

        {noScreenshots}
      </div>
      {screenshotModalIsOpen && (
        <LightboxModal
          onClose={() => {
            setScreenshotModalIsOpen(false);
          }}
          startFileName={screenshotFileName}
          isOpen={screenshotModalIsOpen}
          mediaItems={screenshots}
        />
      )}
    </>
  );
};
export default ImageCollectionModern;

import {
  FetchOptions,
  getAuthorizationHeader,
  getBaseURLforAPI,
} from "~/helpers/api";

export const CACHE_TTL = {
  VERY_LONG: 14000,
  LONG: 8000,
  MEDIUM: 4000,
  SHORT: 1800,
  VERY_SHORT: 160,
};

export async function FetchAPIStatic<T>(
  endpoint: string,
  tags?: string[],
  params?: Record<string, any>,
): Promise<T> {
  return await FetchAPI<T>(endpoint, null, 3600, tags, params);
}

export async function FetchAPI<T>(
  endpoint: string,
  accessToken?: string | null,
  revalidate?: number,
  tags?: string[],
  params?: Record<string, any>,
): Promise<T> {
  const API_BASE_URL = getBaseURLforAPI("server");
  const authorizationHeader = accessToken
    ? getAuthorizationHeader(accessToken)
    : {};

  const options: FetchOptions = {
    method: "GET",
    headers: {
      "fort-knox": "ignore-this-check",
      ...authorizationHeader,
    },
  };

  // Handle caching options (keeping existing logic)
  if (revalidate === 0 || !revalidate) {
    options.next = { revalidate: 0 };
  } else if (accessToken) {
    options.next = { revalidate: 0 };
  } else if (revalidate && revalidate < 0) {
    options.next = { revalidate: 0 };
  } else if (revalidate && revalidate > 0) {
    options.next = { revalidate: revalidate, tags: tags || ["public-data"] };
  }

  if (process.env.NODE_ENV === "development") {
    options.next = { revalidate: 0 };
  }

  // Build URL with parameters if provided
  let url = `${API_BASE_URL}${endpoint}`;
  if (params) {
    const queryParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(
          (v) =>
            v !== undefined &&
            v !== null &&
            queryParams.append(key, v.toString()),
        );
      } else if (value !== undefined && value !== null) {
        queryParams.append(key, value.toString());
      }
    });
    const queryString = queryParams.toString();
    if (queryString) {
      url += `${url.includes("?") ? "&" : "?"}${queryString}`;
    }
  }

  // Add request logging
  // console.log(
  //   "\x1b[36m%s\x1b[0m", // Cyan color
  //   `🚀 API Request: ${endpoint}`,
  // );

  try {
    //const startTime = performance.now();
    const response = await fetch(url, options);
    //const endTime = performance.now();

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error("\x1b[31m", errorData, "\x1b[0m");
      throw new Error(
        `API Error: ${endpoint} ${response.status} ${errorData?.message || response.statusText}`,
      );
    }

    const data = await response.json();

    // Add success logging
    // console.log(
    //   "\x1b[32m%s\x1b[0m", // Green color
    //   `✅ API Success: ${endpoint}`,
    //   "\n",
    //   {
    //     status: response.status,
    //     duration: `${Math.round(endTime - startTime)}ms`,
    //     dataSize: JSON.stringify(data).length,
    //   },
    // );

    return data;
  } catch (error) {
    // Update error logging
    console.error(
      "\x1b[31m%s\x1b[0m", // Red color
      `❌ API Error: ${endpoint}`,
      "\n",
      error,
    );
    throw error;
  }
}

// Update revalidate function to accept custom tag
export async function revalidateAPIData(tag?: string, path?: string) {
  try {
    await fetch("/api/revalidate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tag: tag || "public-data", // Use custom tag or fallback
        path,
      }),
    });
  } catch (error) {
    console.error("Failed to revalidate cache:", error);
  }
}
